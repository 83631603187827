import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import Quiz from "./components/Quiz";
import MainMenu from "./components/MainMenu";
import Container from "@material-ui/core/Container";
import { Snackbar, IconButton, LinearProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";

//const endPoint = './mock.json';

function App() {
  const [start, setStart] = useState(false);
  const [open, setOpen] = useState(false);

  const [data, setData] = useState({
    questions: [{ question: "" }],
    isFetching: false,
  });

  // load quiz data
  useEffect(() => {
    document.title = "Grepsoft Quiz App";
  }, []);

  const restartQuiz = () => {
    setStart(false);
  };

  async function loadQuiz(endPoint) {
    setData({
      isFetching: true,
    });
    const data = await axios.get(endPoint);
    if (data.data.results) {
      if (data.data.results.length > 0) {
        setStart(true);
        setData({ questions: data.data.results, isFetching: false });
      } else {
        setData({
          isFetching: false,
        });
        setOpen(true);
      }
    } else {
      setOpen(true);
    }
  }

  function onStart(ep) {
    loadQuiz(ep);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Container maxWidth="md">
      {data.isFetching ? (
        <Box width="100%" mr={1}>
          <LinearProgress variant="indeterminate" />
        </Box>
      ) : start ? (
        <Quiz data={data} onRestart={restartQuiz} />
      ) : (
        <MainMenu start={onStart} />
      )}

      <div>
        <Snackbar
          open={open}
          onClose={handleRequestClose}
          autoHideDuration={5000}
          message="No Quiz data."
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          action={
            <>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </>
          }
        />
      </div>
    </Container>
  );
}

export default App;
