import React, { useRef } from "react";
import Dropdown from "./Dropdown";
import { urlBuilder } from "../util";
import {
  Button,
  Paper,
  List,
  ListItem,
  Slider,
  Typography,
} from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Box from "@material-ui/core/Box";

/**
 * URL (no selection) : https://opentdb.com/api.php?amount=<number of questions>
 * https://opentdb.com/api.php?
 * amount=<number of questions>&
 * diffuculty=<easy|medium|hard>&
 * type=<multiple|boolean>
 */

export default function MainMenu(props) {
  const selection = useRef({
    amount: 10,
    category: -1,
    type: -1,
    difficulty: -1,
  });

  function start() {
    props.start(urlBuilder(selection.current));
  }

  function select(e) {
    switch (e.type) {
      case "category":
        selection.current.category = e.selection;
        break;
      case "type":
        selection.current.type = e.selection;
        break;
      case "difficulty":
        selection.current.difficulty = e.selection;
        break;
      default:
        break;
    }
  }

  function questionSliderChange(e, value) {
    selection.current.questions = value;
  }
  return (
    <Paper>
      <List>
        <ListItem>
          <Box width="100%">
            <Dropdown
              data={dropdownCategory.trivia_categories}
              type={"category"}
              onSelect={select}
            />
          </Box>
        </ListItem>
        <ListItem>
          <Dropdown
            data={dropdownDifficulty.trivia_difficulty}
            type={"difficulty"}
            onSelect={select}
          />
        </ListItem>
        <ListItem>
          <Dropdown
            data={dropdownType.trivia_type}
            type={"type"}
            onSelect={select}
          />
        </ListItem>
        <ListItem>
          <Typography id="questions" gutterBottom>
            Number of Questions
          </Typography>
          <Slider
            aria-labelledby="questions"
            defaultValue={10}
            step={10}
            marks={questionMarks}
            min={10}
            max={30}
            onChangeCommitted={questionSliderChange}
          />
        </ListItem>

        <Button
          className="float-right"
          color="primary"
          variant="contained"
          onClick={start}
        >
          <PlayArrowIcon />
        </Button>
      </List>
    </Paper>
  );
}

const questionMarks = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
];

const dropdownCategory = {
  trivia_categories: [
    { id: -1, name: "Any Category" },
    { id: 9, name: "General Knowledge" },
    { id: 10, name: "Entertainment: Books" },
    { id: 11, name: "Entertainment: Film" },
    { id: 12, name: "Entertainment: Music" },
    { id: 13, name: "Entertainment: Musicals & Theatres" },
    { id: 14, name: "Entertainment: Television" },
    { id: 15, name: "Entertainment: Video Games" },
    { id: 16, name: "Entertainment: Board Games" },
    { id: 17, name: "Science & Nature" },
    { id: 18, name: "Science: Computers" },
    { id: 19, name: "Science: Mathematics" },
    { id: 20, name: "Mythology" },
    { id: 21, name: "Sports" },
    { id: 22, name: "Geography" },
    { id: 23, name: "History" },
    { id: 24, name: "Politics" },
    { id: 25, name: "Art" },
    { id: 26, name: "Celebrities" },
    { id: 27, name: "Animals" },
    { id: 28, name: "Vehicles" },
    { id: 29, name: "Entertainment: Comics" },
    { id: 30, name: "Science: Gadgets" },
    { id: 31, name: "Entertainment: Japanese Anime & Manga" },
    { id: 32, name: "Entertainment: Cartoon & Animations" },
  ],
};

const dropdownDifficulty = {
  trivia_difficulty: [
    { id: -1, name: "Any Difficulty" },
    { id: 0, name: "Easy" },
    { id: 1, name: "Medium" },
    { id: 2, name: "Hard" },
  ],
};

const dropdownType = {
  trivia_type: [
    { id: -1, name: "Any Type" },
    { id: 0, name: "Multiple Choice" },
    { id: 1, name: "True / False" },
  ],
};
