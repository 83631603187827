import React from 'react';
import { Typography } from '@material-ui/core';

function QuestionIndex(props) {
    return (
      <div>        
        <span className="hand-writing">Question # {props.index}</span>
      </div>
    );
}

export default QuestionIndex;