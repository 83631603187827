import React, { useState } from "react";
import { Select } from "@material-ui/core";

export default function Dropdown(props) {
  const [val, setVal] = useState("");

  const dropdown = props.data.map((val) => (
    <option key={val.id} value={val.id}>
      {val.name}
    </option>
  ));

  function select(e) {
    setVal(e.target.value);
    props.onSelect({ selection: e.target.value, type: props.type });
  }

  return (
    <Select fullWidth native value={val} onChange={select}>
      {dropdown}
    </Select>
  );
}
