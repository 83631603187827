import React from "react";
import cx from "classnames";
import { ListItem } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

function OneAnswer(props) {
    var classnames = cx("pointer", 'hand-writing', 'answer', {
      wrong : props.answer === props.wrong && props.highlightInCorrect,
    correct: props.correct && props.highlightCorrect,
  });

  function onAnswer(e) {
    props.onAnswer(props.answer);
  }

  const icon =
    props.answer === props.wrong && props.highlightInCorrect ? (
      <ClearIcon className="wrong" />
    ) : props.correct && props.highlightCorrect ? (
      <DoneIcon className="right" />
    ) : (
      ""
    );

  return (
    <ListItem className={classnames} onClick={onAnswer}>
      
      {props.answer}
      {icon}
    </ListItem>
  );
}

export default OneAnswer;
