import React, { Fragment } from "react";
import { Button } from "@material-ui/core";

function Action(props) {
  const restart = props.showFinish;


  const nextBtn = !restart ? (
    <Button
      className="float-right"
      color="primary"
      variant="contained"
      disabled={props.disable}
      onClick={props.next}
    >
      Next
    </Button>
  ) : null;

    return (
      <Fragment>
        {nextBtn}
      </Fragment>
    );

}

export default Action;
