import React from 'react';
function Stats(props) {

  return (
    <div>      
      <span className="hand-writing">
        Correct: {props.count} / {props.total}
      </span>
      </div>
    );
}

export default Stats;